import SeiyanLogo from '../icons/Seiyan.png';
import GonadLogo from '../icons/Gonad.png';
import whaleLogo from '../icons/Sei Whale.png';
import rexLogo from '../icons/Inspector.png';
import popoLogo from '../icons/POPO.png';
import seiyunLogo from '../icons/Seiyun.png';
import milliLogo from '../icons/MILLI.png';
import shishoLogo from '../icons/Shisho.png';
import slowLogo from '../icons/SLOW.png';
import happyLogo from '../icons/Happy Ape.png';
import chipsLogo from '../icons/CHIPS.png';
import UMILogo from '../icons/UMI.png';
import OolongLogo from '../icons/Oolong.png';
import ShenronLogo from '../icons/Shenron.png';
import wilsonLogo from '../icons/WILSON.png';
import waitLogo from '../icons/WAIT.png';

const memeData = [
    {
        name: 'CHIP$ on SEI',
        contractAddress: '0xBd82f3bfE1dF0c84faEC88a22EbC34C9A86595dc',
        pairAddress: '0x6F695E89f17ab7476EA9CDd4dA2b24F55A853a57',
        description: 'Cooked and Consumed by the Cappy Collective.',
        logo: chipsLogo,
        twitter: 'https://x.com/chipsonsei',
        dexScreener: 'https://dexscreener.com/seiv2/0xBd82f3bfE1dF0c84faEC88a22EbC34C9A86595dc',
        dragonSwap: 'https://dragonswap.app/swap?inputCurrency=0xBd82f3bfE1dF0c84faEC88a22EbC34C9A86595dc&outputCurrency=0x3894085Ef7Ff0f0aeDf52E2A2704928d1Ec074F1',
        coinGecko: 'https://www.geckoterminal.com/sei-evm/pools/0x6F695E89f17ab7476EA9CDd4dA2b24F55A853a57',
    },
    {
        name: 'gonad',
        contractAddress: '0xd78BE621436e69C81E4d0e9f29bE14C5EC51E6Ae',
        pairAddress: '0xBE3B8eB9AE801395040b013B73Aa539f46A9c6a8',
        description: 'A reproductive gland.',
        logo: GonadLogo,
        twitter: 'https://x.com/gonadGND',
        dexScreener: 'https://dexscreener.com/seiv2/0xd78BE621436e69C81E4d0e9f29bE14C5EC51E6Ae',
        dragonSwap: 'https://dragonswap.app/swap?inputCurrency=0xd78BE621436e69C81E4d0e9f29bE14C5EC51E6Ae&outputCurrency=0x3894085Ef7Ff0f0aeDf52E2A2704928d1Ec074F1',
        coinGecko: 'https://www.geckoterminal.com/sei-evm/pools/0xbe3b8eb9ae801395040b013b73aa539f46a9c6a8',
    },
    {
        name: 'Happy Ape',
        contractAddress: '0x490736a46aaBa689Db5265510B5C53aF4c23DEd4',
        pairAddress: '0x7cB22A0C7191dE33B043125cB601267955751C46',
        description: 'Together, Apes, Happy',
        logo: happyLogo,
        twitter: 'https://x.com/happyapebot',
        dexScreener: 'https://dexscreener.com/seiv2/0x7cB22A0C7191dE33B043125cB601267955751C46',
        dragonSwap: 'https://dragonswap.app/swap?inputCurrency=0x490736a46aaBa689Db5265510B5C53aF4c23DEd4&outputCurrency=0x3894085Ef7Ff0f0aeDf52E2A2704928d1Ec074F1',
        coinGecko: 'https://www.geckoterminal.com/sei-evm/pools/0x7cB22A0C7191dE33B043125cB601267955751C46',
    },
    {
        name: 'Inspector',
        contractAddress: '0x6291148AE49e0f0d847bB97C4E060E49D87638bA',
        pairAddress: '0x69a8b6840cc39091942132faf6991aea806463f2',
        description: 'First token launched from meme.trade on Sei.',
        logo: rexLogo,
        twitter: 'https://x.com/RexOnSEI',
        dexScreener: 'https://dexscreener.com/seiv2/0x6291148AE49e0f0d847bB97C4E060E49D87638bA',
        dragonSwap: 'https://dragonswap.app/swap?inputCurrency=0x6291148AE49e0f0d847bB97C4E060E49D87638bA&outputCurrency=0x3894085Ef7Ff0f0aeDf52E2A2704928d1Ec074F1',
        coinGecko: 'https://www.geckoterminal.com/sei-evm/pools/0x69a8b6840cc39091942132faf6991aea806463f2',
    },
    {
        name: 'LORD SHISHO',
        contractAddress: '0xd0C7EdF2109bE009364ac3DB76C6fE8a75728657',
        pairAddress: '0x5f720f5893bfa42f9d008fc34d4b11e9dd160eb1',
        description: 'In Shisho we trust. The first coin to send it from memes.seipex.fi',
        logo: shishoLogo,
        twitter: 'https://x.com/lordshisho/',
        dexScreener: 'https://dexscreener.com/seiv2/0xd0C7EdF2109bE009364ac3DB76C6fE8a75728657',
        dragonSwap: 'https://dragonswap.app/swap?inputCurrency=0xd0C7EdF2109bE009364ac3DB76C6fE8a75728657&outputCurrency=0x3894085Ef7Ff0f0aeDf52E2A2704928d1Ec074F1',
        coinGecko: 'https://www.geckoterminal.com/sei-evm/pools/0x5f720f5893bfa42f9d008fc34d4b11e9dd160eb1',
    },
    {
        name: 'MILLI',
        contractAddress: '0x95597EB8D227a7c4B4f5E807a815C5178eE6dBE1',
        pairAddress: '0xf1d35df54a1247b047a52f8acc2359b415ba8a9a',
        description: 'The first dog coin on Sei.',
        logo: milliLogo,
        twitter: 'https://x.com/MilliCoinSei',
        dexScreener: 'https://dexscreener.com/seiv2/0x95597EB8D227a7c4B4f5E807a815C5178eE6dBE1',
        dragonSwap: 'https://dragonswap.app/swap?inputCurrency=0x95597EB8D227a7c4B4f5E807a815C5178eE6dBE1&outputCurrency=0x3894085Ef7Ff0f0aeDf52E2A2704928d1Ec074F1',
        coinGecko: 'https://www.coingecko.com/en/coins/milli-coin',
    },
    {
        name: 'Oolong',
        contractAddress: '0x74547390fe40D5cEA666008D44feBe1340E7a3B0',
        pairAddress: '0x194a1b47d0b0FF338280bF370615B1938838FEBd',
        description: "Don't get me started on Sydney Sweeney.",
        logo: OolongLogo,
        twitter: 'https://x.com/oolongsei',
        dexScreener: 'https://dexscreener.com/seiv2/0x74547390fe40D5cEA666008D44feBe1340E7a3B0',
        dragonSwap: 'https://dragonswap.app/swap?inputCurrency=0x74547390fe40D5cEA666008D44feBe1340E7a3B0&outputCurrency=0x3894085Ef7Ff0f0aeDf52E2A2704928d1Ec074F1',
        coinGecko: 'https://www.geckoterminal.com/sei-evm/pools/0x194a1b47d0b0FF338280bF370615B1938838FEBd',
    },
    {
        name: 'Popo The Cat',
        contractAddress: '0xC18b6a15FB0ceaf5eb18696EeFCb5bc7b9107149',
        pairAddress: '0xa2aee4f82023c6614f3ab40041c3b434ff97b439',
        description: 'The first meme coin on Sei V2, run by the Sei community.',
        logo: popoLogo,
        twitter: 'https://x.com/Popo_On_Sei',
        dexScreener: 'https://dexscreener.com/seiv2/0xC18b6a15FB0ceaf5eb18696EeFCb5bc7b9107149',
        dragonSwap: 'https://dragonswap.app/swap?inputCurrency=0xC18b6a15FB0ceaf5eb18696EeFCb5bc7b9107149&outputCurrency=0x3894085Ef7Ff0f0aeDf52E2A2704928d1Ec074F1',
        coinGecko: 'https://www.coingecko.com/en/coins/popo-the-cat',
    },
    {
        name: 'SeiWhale',
        contractAddress: '0x67DA7010C6c231AD620E3940e707adB6c1a08f23',
        pairAddress: '0x7990251ed0166BF90dC82e865B5aFE382e228Ff4',
        description: 'A fun and unique meme coin with a strong community!',
        logo: whaleLogo,
        twitter: 'https://x.com/seithewhale',
        dexScreener: 'https://dexscreener.com/seiv2/0x67DA7010C6c231AD620E3940e707adB6c1a08f23',
        dragonSwap: 'https://dragonswap.app/swap?inputCurrency=0x67DA7010C6c231AD620E3940e707adB6c1a08f23&outputCurrency=0x3894085Ef7Ff0f0aeDf52E2A2704928d1Ec074F1',
        coinGecko: 'https://www.coingecko.com/en/coins/seiwhale',
    },
    {
        name: 'Seiyan',
        contractAddress: '0x5f0E07dFeE5832Faa00c63F2D33A0D79150E8598',
        pairAddress: '0x8ba502d9243fa5e44678044098be9782cebafda5',
        description: 'The first meme coin on the Sei network!',
        logo: SeiyanLogo,
        twitter: 'https://x.com/seiyantoken9000',
        dexScreener: 'https://dexscreener.com/seiv2/0x5f0E07dFeE5832Faa00c63F2D33A0D79150E8598',
        dragonSwap: 'https://dragonswap.app/swap?inputCurrency=0x5f0E07dFeE5832Faa00c63F2D33A0D79150E8598&outputCurrency=0x3894085Ef7Ff0f0aeDf52E2A2704928d1Ec074F1',
        coinGecko: 'https://www.coingecko.com/en/coins/seiyan',
    },
    {
        name: 'SEIYUN COIN',
        contractAddress: '0xECf7f1EFC9620A911339619C91c9FA0f71e0600E',
        pairAddress: '0xb1c6cf64929e020f64072be891bbfe0f98eaebd3',
        description: 'Seiyun is the future of memes.',
        logo: seiyunLogo,
        twitter: 'https://x.com/seiyuncoin',
        dexScreener: 'https://dexscreener.com/seiv2/0xECf7f1EFC9620A911339619C91c9FA0f71e0600E',
        dragonSwap: 'https://dragonswap.app/swap?inputCurrency=0xECf7f1EFC9620A911339619C91c9FA0f71e0600E&outputCurrency=0x3894085Ef7Ff0f0aeDf52E2A2704928d1Ec074F1',
        coinGecko: 'https://www.geckoterminal.com/sei-evm/pools/0xb1c6cf64929e020f64072be891bbfe0f98eaebd3',
    },
    {
        name: 'Shenron',
        contractAddress: '0x0DD9e6A6AEb91f1e3596F69d5443b6ca2e864896',
        pairAddress: '0xebc0E7647777b875F9Db0e695B28C55808473F49',
        description: 'Will you have what it takes to summon Shenron the eternal dragon?',
        logo: ShenronLogo,
        twitter: 'https://x.com/shenrononsei',
        dexScreener: 'https://dexscreener.com/seiv2/0x0DD9e6A6AEb91f1e3596F69d5443b6ca2e864896',
        dragonSwap: 'https://dragonswap.app/swap?inputCurrency=0x0DD9e6A6AEb91f1e3596F69d5443b6ca2e864896&outputCurrency=0x3894085Ef7Ff0f0aeDf52E2A2704928d1Ec074F1',
        coinGecko: 'https://www.geckoterminal.com/sei-evm/pools/0xebc0E7647777b875F9Db0e695B28C55808473F49',
    },
    {
        name: 'SLOW',
        contractAddress: '0xc972106cA2560fA0065e1CA01C9dC6D6e80cC7a1',
        pairAddress: '0x148B0A6c99eC933F71b59bC74F3C9165C950c917',
        description: 'The $SLOW-est token on the fastest blockchain.',
        logo: slowLogo,
        twitter: 'https://x.com/Slow_Sei',
        dexScreener: 'https://dexscreener.com/seiv2/0xc972106cA2560fA0065e1CA01C9dC6D6e80cC7a1',
        dragonSwap: 'https://dragonswap.app/swap?inputCurrency=0xc972106cA2560fA0065e1CA01C9dC6D6e80cC7a1&outputCurrency=0x3894085Ef7Ff0f0aeDf52E2A2704928d1Ec074F1',
        coinGecko: 'https://www.geckoterminal.com/sei-evm/pools/0x148B0A6c99eC933F71b59bC74F3C9165C950c917',
    },
    {
        name: 'UMI',
        contractAddress: '0x893CC8a006b88BeeF66c79bBb1C14b9c6c0AD7Cc',
        pairAddress: '0x79B87c4d8d07a379f9CBb30F9740671E4c37f8Cd',
        description: 'Master roshi sent me.',
        logo: UMILogo,
        twitter: 'https://x.com/umionsei',
        dexScreener: 'https://dexscreener.com/seiv2/0x893CC8a006b88BeeF66c79bBb1C14b9c6c0AD7Cc',
        dragonSwap: 'https://dragonswap.app/swap?inputCurrency=0x893CC8a006b88BeeF66c79bBb1C14b9c6c0AD7Cc&outputCurrency=0x3894085Ef7Ff0f0aeDf52E2A2704928d1Ec074F1',
        coinGecko: 'https://www.geckoterminal.com/sei-evm/pools/0x79B87c4d8d07a379f9CBb30F9740671E4c37f8Cd',
    },
    {
        name: 'WAIT',
        contractAddress: '0x00bc9aF972B5fb3b7fA04827bD3C17d4599BAd8b',
        pairAddress: '0xc68be4F8408B2a2335c90454bf87102A87b097aC',
        description: 'patience is a virtue, just wait.',
        logo: waitLogo,
        twitter: 'https://x.com/wait_onit',
        dexScreener: 'https://dexscreener.com/seiv2/0x00bc9aF972B5fb3b7fA04827bD3C17d4599BAd8b',
        dragonSwap: 'https://dragonswap.app/swap?inputCurrency=0x00bc9aF972B5fb3b7fA04827bD3C17d4599BAd8b&outputCurrency=0x3894085Ef7Ff0f0aeDf52E2A2704928d1Ec074F1',
        coinGecko: 'https://www.geckoterminal.com/sei-evm/pools/0xc68be4F8408B2a2335c90454bf87102A87b097aC',
    },
    {
        name: 'WILSON',
        contractAddress: '0x962Aae191622498BcA205c1c1B73E59aC7d295f2',
        pairAddress: '0x2183ac6777c0547828F1A8bD0ccab31aE384dF94',
        description: 'The Volleyball Cult Coin on Sei. Are you Wilson enough?',
        logo: wilsonLogo,
        twitter: 'https://x.com/wilsononsei',
        dexScreener: 'https://dexscreener.com/seiv2/0x962Aae191622498BcA205c1c1B73E59aC7d295f2',
        dragonSwap: 'https://dragonswap.app/swap?inputCurrency=0x962Aae191622498BcA205c1c1B73E59aC7d295f2&outputCurrency=0x3894085Ef7Ff0f0aeDf52E2A2704928d1Ec074F1',
        coinGecko: 'https://www.geckoterminal.com/sei-evm/pools/0x2183ac6777c0547828F1A8bD0ccab31aE384dF94',
    },


];

export default memeData;
