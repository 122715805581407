import s3Logo from '../icons/S3.png';
import seiWhatLogo from '../icons/W3BTHR33.png';
import layersGrilledOnionLogo from '../icons/Grilled Onion.png';
import seiFuckersLogo from '../icons/Fuckers.png'; 
import alterEcoLogo from '../icons/Alter Eco.png';
import cappyradioLogo from '../icons/Cappy Radio.png';
import cjLogo from '../icons/CJ.png';
import greenityLogo from '../icons/Sei Something.png';
import artLogo from '../icons/Art Logo.png';

const spacesData = [
  {
    days: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
    NameofSpace: 'Alter Eco',
    timeUTC: '6:00 PM UTC', // 11:00 AM PST converted to UTC
    twitter: 'https://x.com/AlterEcoShow',
    hostsUsernames: ['@heathenft', '@roylopez_s3', '@0xKingBall', '@dogwiz_eth'],
    spacesLogo: alterEcoLogo,
    s3Affiliated: true,
    description: "A daily show about nothing and everything. All Chains—No Brains.",
    Featured: false,
  },

  {
    days: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
    NameofSpace: 'SEI What!?...In The Morning',
    timeUTC: '3:00 PM UTC',
    twitter: 'https://x.com/SEIWHATSHOW',
    hostsUsernames: ['@niftyq_','@BiggiePoppins'],
    spacesLogo: seiWhatLogo,
    description: "The Morning Show of Sei.",
    Featured: true,
  },
  {
    days: ['Monday', 'Tuesday', 'Thursday', 'Friday'],
    NameofSpace: 'Daily S3X',
    timeUTC: '10:00 PM UTC',
    twitter: 'https://x.com/s3_labs',
    hostsUsernames: ['@roylopez_s3'],
    s3Affiliated: true,
    spacesLogo: s3Logo,
    description: "The daily space for all things Sei.",
    Featured: true,
  },
  {
    days: ['Wednesday'],
    NameofSpace: 'Layers, w/ Grilled Onion',
    timeUTC: '10:00 PM UTC',
    twitter: 'https://x.com/Grilled_Onion_',
    hostsUsernames: ['@Grilled_Onion_'],
    spacesLogo: layersGrilledOnionLogo,
    description: "Layers is a series of 1 on 1 interviews, hosted by Fuckers team member Grilled Onion, with the intention to give listeners an hour of uninterrupted time to learn more about people they see in and around the Sei ecosystem.",
    Featured: true,
  },
  {
    days: ['Saturday'],
    NameofSpace: 'SEIturday Night Live',
    timeUTC: '2:00 AM UTC',
    twitter: 'https://x.com/FUCKERSFORLIFE',
    hostsUsernames: ['@notacaptain_'],
    spacesLogo: seiFuckersLogo,
    description: "A WEEKLY SHOW DEDICATED GOOD VIBES AND A FUN TIME. COME FUCK AROUND N FIND OUT",
    Featured: true,
  },
  {
    days: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
    NameofSpace: 'Cappy Radio',
    timeUTC: '1:30 PM UTC & 6:30 PM UTC',
    twitter: 'https://x.com/GUTTERSWOLE',
    hostsUsernames: ['@GUTTERSWOLE'],
    spacesLogo: cappyradioLogo,
    description: "Tune in daily for live shows featuring music from every genre and every corner of the world.",
    Featured: false,
  },
  
  { 
  days: ["Monday","Tuesday","Wednesday","Thursday"],
  NameofSpace: 'Sei Something',
  timeUTC: '11:00 AM UTC',
  twitter: 'https://x.com/MrGreenity',
  hostsUsernames: ["@MrGreenity"],
  s3Affiliated: false,
  spacesLogo: greenityLogo, // Replace with the correct logo
  description: 'A multi-community space to connect people and vibe together.',
},  
  {
  days: ['Friday'],
  NameofSpace: 'Sei Weekly Roundup',
  timeUTC: '8:00 PM UTC',
  twitter: 'https://x.com/CJNFT__/',
  hostsUsernames: ["@CJNFT__"],
  s3Affiliated: false,
  spacesLogo: cjLogo,
  description: 'Catch up on the latest trends and news on Sei every Friday',
  featured: false,
},
{
  days: ["Thursday"],
  NameofSpace: 'ifyou SEI Show',
  timeUTC: '12:00 AM UTC',
  twitter: 'https://x.com/ifyouSEI',
  hostsUsernames: ["@ifyouSei"],
  s3Affiliated: true,
  spacesLogo: s3Logo, // Replace with the correct logo
  description: 'Chill space, everyone welcome. Come have fun, chill, shill, f*ck around and find out.',
},
{
  days: ["Monday"],
  NameofSpace: '[ART]ificially',
  timeUTC: '10:00 PM UTC',
  twitter: 'https://x.com/heathenft',
  hostsUsernames: ["@heathenft","@tommyk_sei"],
  s3Affiliated: true,
  spacesLogo: artLogo, // Replace with the correct logo
  description: 'Talking about all things art and highlighting creators from $SEI and across Web3!',
}






];

export default spacesData;
