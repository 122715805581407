import starSymphonyLogo from '../icons/Star Symphony.png';
import dragonNinjaLogo from '../icons/Dragon Ninja.png';
import catsVsMonstersLogo from '../icons/Cat VS Monsters.png';

export const seiGamesData = [
  {
    name: "Star Symphony",
    logo: starSymphonyLogo,
    description: "Competitive Web3 rhythm game by Isekai Studio with storylines like 'Constellation Wars' and multiplayer modes.",
    features: [
      "Fun-first gameplay, focusing on enjoyable experiences rather than P2E mechanics.",
      "Supports indie music creators, helping them monetize their work.",
      "Available on Android with cross-platform compatibility coming soon.",
      "Weekly leaderboard-based rewards and multiplayer clan wars."
    ],
    website: "https://www.starsymphony.io/",
    twitter: "https://x.com/starsymphony_io",
    telegram: "" // Example Telegram link
  },
  {
    name: "Dragon Ninja",
    logo: dragonNinjaLogo,
    description: "Rogue-like survival game on SEI where players fight waves of enemies, upgrading their characters with new skills.",
    features: [
      "First AI-driven rogue-like game in Web3.",
      "Available through Telegram.",
      "Special airdrop allocation for NFT holders."
    ],
    website: "https://dragon-ninja.io/#/",
    twitter: "https://x.com/dragonninja_io",
    telegram: "https://t.me/Dragon_Ninja_Chatgroup" // Added Telegram link
  },
  {
    name: "Cats Vs Monsters",
    logo: catsVsMonstersLogo,
    description: "2D idle P2E RPG game where players control cat soldiers to battle monsters and bosses, earning $CatM tokens.",
    features: [
      "Smooth gameplay with a large player base.",
      "250k player-base, 26k daily active users.",
      "$CatM token with potential future airdrops."
    ],
    twitter: "https://x.com/catvsmonsters",
    telegram: "https://t.me/cat_vs_monsters_bot"
  }
];
