import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FaSort } from 'react-icons/fa'; // Import necessary icons
import palletLogo from '../icons/Pallet.png'; // Import logo
import timePeriods from '../data/palletAPIData'; // Import the time periods data file

const Analytics = () => {
  const [data, setData] = useState([]);
  const [seiPrice, setSeiPrice] = useState(null); // State to store SEI price
  const [sortConfig, setSortConfig] = useState({ key: 'volume_latest', direction: 'descending' });
  const [selectedTimePeriod, setSelectedTimePeriod] = useState('7day');
  const [selectedPageSize, setSelectedPageSize] = useState(25);

  // Fetch SEI price from CoinGecko API
  const getCurrentSeiPrice = async () => {
    try {
      const response = await axios.get('https://api.coingecko.com/api/v3/simple/price?ids=sei-network&vs_currencies=usd');
      const price = parseFloat(response.data['sei-network'].usd);
      setSeiPrice(price);
    } catch (error) {
      console.error('Error fetching SEI price:', error);
    }
  };

  // Fetch Pallet data and SEI price
  useEffect(() => {
    const fetchData = async () => {
      try {
        const lookback = timePeriods[selectedTimePeriod];
        const response = await fetch(`https://api.craftycanines.xyz/api/pallet/NFTvolume?lookback=${lookback}&pageSize=${selectedPageSize}`);
        const result = await response.json();
        setData(result);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
    getCurrentSeiPrice(); // Fetch SEI price
  }, [selectedTimePeriod, selectedPageSize]);

  const sortedData = [...data].sort((a, b) => {
    const aValue = a[sortConfig.key];
    const bValue = b[sortConfig.key];

    if (typeof aValue === 'number' && typeof bValue === 'number') {
      return sortConfig.direction === 'ascending' ? aValue - bValue : bValue - aValue;
    }
    if (aValue < bValue) {
      return sortConfig.direction === 'ascending' ? -1 : 1;
    }
    if (aValue > bValue) {
      return sortConfig.direction === 'ascending' ? 1 : -1;
    }
    return 0;
  });

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  return (
<div className="flex flex-col items-center justify-center text-white p-4 pb-1 overflow-x-hidden overflow-y-auto">
      <div className="bg-gray-900 p-6 rounded-lg shadow-lg mb-6 mx-4 sm:mx-6 md:mx-8 lg:mx-10 w-full max-w-5xl">
        <div className="flex items-center justify-start mt-2">
          <img src={palletLogo} alt="Pallet Logo" className="w-8 h-8 mr-2" />
          <h2 className="text-xl font-bold mb-0 flex-grow text-center">
            Pallet NFT Exchange Volume
          </h2>
        </div>

        <div className="mt-4 flex justify-between">
          <div>
            <label htmlFor="time-period" className="mr-2 text-lg">Time Period:</label>
            <select
              id="time-period"
              value={selectedTimePeriod}
              onChange={(e) => setSelectedTimePeriod(e.target.value)}
              className="bg-gray-800 text-white p-2 rounded-md"
            >
              <option value="1day">1 Day</option>
              <option value="7day">7 Days</option>
              <option value="14day">14 Days</option>
              <option value="30day">30 Days</option>
              <option value="all_time">All Time</option>
            </select>
          </div>
          <div>
            <label htmlFor="page-size" className="mr-2 text-lg">Number of Collections:</label>
            <select
              id="page-size"
              value={selectedPageSize}
              onChange={(e) => setSelectedPageSize(e.target.value)}
              className="bg-gray-800 text-white p-2 rounded-md"
            >
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </div>
        </div>

        <div className="mt-4">
          <table className="min-w-full border-collapse">
            <thead>
              <tr className="bg-gray-700">
                <th className="border px-4 py-2 text-center" onClick={() => requestSort('name')}>
                  Collection <FaSort className="inline ml-1" />
                </th>
                <th className="border px-4 py-2 text-center hidden md:table-cell" onClick={() => requestSort('sei_address')}>
                  Collection Address <FaSort className="inline ml-1" />
                </th>
                <th className="border px-4 py-2 text-center" onClick={() => requestSort('floor')}>
                  Floor Price <FaSort className="inline ml-1" />
                </th>
                <th className="border px-4 py-2 text-center hidden md:table-cell" onClick={() => requestSort('supply')}>
                  Supply <FaSort className="inline ml-1" />
                </th>
                <th className="border px-4 py-2 text-center hidden md:table-cell" onClick={() => requestSort('owners')}>
                  Owners <FaSort className="inline ml-1" />
                </th>
                <th className="border px-4 py-2 text-center" onClick={() => requestSort('volume_latest')}>
                  Volume <FaSort className="inline ml-1" />
                </th>
              </tr>
            </thead>
            <tbody>
              {sortedData.map((item, index) => (
                <tr key={index} className="bg-gray-800 text-center">
                  <td className="border px-4 py-2 text-center">
                    <div className="hidden md:block pb-1">
                      <a href={`https://pallet.exchange/collection/${item.sei_address}`} target="_blank" rel="noopener noreferrer">
                        <span>{item.name}</span>
                      </a>
                    </div>

                    <div className="w-10 h-10 mx-auto">
                      <a href={`https://pallet.exchange/collection/${item.sei_address}`} target="_blank" rel="noopener noreferrer">
                        <img src={item.pfp} alt={`${item.name} Logo`} className="w-10 h-10 rounded-full" />
                      </a>
                    </div>
                  </td>

                  <td className="border px-4 py-2 text-center hidden md:table-cell">
                    <a href={`https://www.seiscan.app/pacific-1/contracts/${item.sei_address}`} className="text-blue-400 hover:underline">
                      {`${item.sei_address.slice(0, 6)}...${item.sei_address.slice(-4)}`}
                    </a>
                  </td>
                  
                  {/* Convert floor price to USD */}
<td className="border px-4 py-2">
  {seiPrice ? `$${(item.floor * seiPrice).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}` : 'Loading...'}
</td>

<td className="border px-4 py-2 text-center hidden md:table-cell">
  {item.supply.toLocaleString()}
</td>

<td className="border px-4 py-2 text-center hidden md:table-cell">
  {item.owners.toLocaleString()}
</td>

{/* Convert volume to USD */}
<td className="border px-4 py-2">
 {seiPrice ? `$${Math.floor(item.volume_latest * seiPrice).toLocaleString()}` : 'Loading...'}

</td>


                </tr>
              ))}
            </tbody>
          </table>
        </div>

      </div>
    </div>
  );
};

export default Analytics;
