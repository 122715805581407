// src/components/Home.js
import React from 'react';
import { motion } from 'framer-motion';
import { homeData } from '../data/homeData'; // Importing the JSON data for Home content
import SEILogo from '../icons/SEI.png'; // Import SEI logo

const cardHoverVariants = {
  hover: {
    scale: 1.05,
    transition: {
      duration: 0.3,
      ease: 'easeInOut',
    },
  },
};

const HomeCard = ({ title, description }) => (
  <motion.div
    initial={{ opacity: 0, y: 50, scale: 0.9 }}
    animate={{ opacity: 1, y: 0, scale: 1 }}
    exit={{ opacity: 0, y: -50, scale: 0.9 }}
    whileHover="hover"
    variants={cardHoverVariants}
    className="bg-gray-900 p-6 md:p-8 rounded-lg shadow-lg flex flex-col items-center space-y-4 mb-8 w-full max-w-3xl mx-auto"
  >
    <div className="text-left w-full">
      <h3 className="text-2xl font-bold mb-2 text-center">{title}</h3>
      <p className="text-sm md:text-gray-400 text-center">{description}</p>
    </div>
  </motion.div>
);

const Home = () => {
  return (
    <div className="flex flex-col items-center justify-center text-white p-4 pb-16 w-full">
      {/* Stylish Header */}
      <div className="w-full text-center mb-2">
        <h1 className="text-3xl md:text-4xl font-bold text-center text-white mb-6 flex items-center justify-center">
          <img src={SEILogo} alt="SEI Logo" className="w-8 h-8 mr-2" />
          SEIGuide.xyz - Your Ultimate Guide to the Sei Blockchain
        </h1>
      </div>

      {/* Home Card section */}
      <div className="grid grid-cols-1 justify-center w-full max-w-5xl mb-8">
        {homeData.map((card, index) => (
          <HomeCard key={index} {...card} />
        ))}
      </div>
    </div>
  );
};

export default Home;
