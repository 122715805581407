import React, { useState } from 'react';
import videoData from '../data/videoData'; // Importing video data
import SEILogo from '../icons/SEI.png'; // Importing the SEI logo
import S3Logo from '../icons/S3.png'; // Importing S3 logo

const SEIGuides = () => {
  // Options for the YouTube player
  const videoOptions = {
    height: '100%', // Set to fully use the container height
    width: '100%', // Responsive width (for different screen sizes)
    playerVars: {
      autoplay: 1, // Autoplay when clicked
    },
  };

  const [videoPlaying, setVideoPlaying] = useState(Array(videoData.length).fill(false));

  const handlePlayVideo = (index) => {
    const updatedState = [...videoPlaying];
    updatedState[index] = true;
    setVideoPlaying(updatedState);
  };

  return (
    <div className="flex flex-col items-center justify-center bg-gray-800 text-white w-full">
      {/* Main description section */}
      <div className="mb-4 text-center w-full">
        <h2 className="text-3xl font-bold mb-2">
          <img
            src={SEILogo}
            alt="SEI Logo"
            className="inline-block w-8 h-8 mr-2"
          />
          New to Sei?
        </h2>
        <p className="text-xl text-white max-w-2xl mx-auto text-center">
          The following videos were created and provided by&nbsp;
          <a
            href="https://www.s3labs.io/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 hover:text-blue-700"
          >
            S3 Labs
          </a>.
        </p>
      </div>

      {/* Video grid section */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 px-2 sm:px-4 md:px-8 w-full">
        {videoData.map((video, index) => (
          <div key={index} className="p-2 md:p-3 bg-gray-900 rounded-lg shadow-lg w-full">
            <h3 className="text-lg font-semibold text-center mb-2 text-white">{video.title}</h3>
            
            <div className="relative w-full h-0 pb-[56.25%] rounded-lg overflow-hidden">
              {!videoPlaying[index] ? (
                <div
                  className="absolute inset-0 flex items-center justify-center bg-black cursor-pointer"
                  onClick={() => handlePlayVideo(index)}
                >
                  <img
                    src={video.thumbnail} // Custom thumbnail (S3 logo in this case)
                    alt={`Thumbnail for ${video.title}`}
                    className="w-full h-full object-cover rounded-lg"
                  />
                  <div className="absolute inset-0 flex items-center justify-center">
<svg className="w-16 h-16 text-blue-500" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M8 5v14l11-7z" />
                    </svg>
                  </div>
                </div>
              ) : (
                <div className="absolute inset-0">
                  <iframe
                    className="w-full h-full rounded-lg"
                    src={`https://www.youtube.com/embed/${video.iframeSrc.split('/').pop().split('?')[0]}?autoplay=1`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SEIGuides;
