import S3Logo from '../icons/S3.png'; // Importing the S3 logo

const videoData = [
  {
    title: "Swap ETH to SEI",
    iframeSrc: "https://www.youtube.com/embed/IwP-GV4zFgE?si=ksNmA8hokgn0Q22Y",
    thumbnail: S3Logo, // Using S3 logo as the thumbnail
  },
  {
    title: "Swap SOL to SEI",
    iframeSrc: "https://www.youtube.com/embed/jLZ8gRreFRI?si=BLeft5aFTeydyz2z",
    thumbnail: S3Logo, // Using S3 logo as the thumbnail
  },
  {
    title: "Buy SEI On Coinbase",
    iframeSrc: "https://www.youtube.com/embed/dFXdmywKneQ?si=Jv2iCuEi9nGImt7J",
    thumbnail: S3Logo, // Using S3 logo as the thumbnail
  },
  {
    title: "What Is PEVM?",
    iframeSrc: "https://www.youtube.com/embed/jjJuabe7j8M?si=hYeovLxD_PQDfIAw",
    thumbnail: S3Logo, // Using S3 logo as the thumbnail
  },
  {
    title: "Import Compass Wallet To MetaMask",
    iframeSrc: "https://www.youtube.com/embed/nOu3es_4pUY?si=5Dr653VkmjzhXHz3",
    thumbnail: S3Logo, // Using S3 logo as the thumbnail
  },
];

export default videoData;
