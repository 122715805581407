// src/data/NavData.js

const navLinks = [
  { name: 'Home', path: '/' },
  { name: 'SEI Guides', path: '/seiguides' },
  { name: 'Wallets', path: '/wallets' },
  { name: 'Exchanges', path: '/exchanges' },
  { name: 'DeFi', path: '/defi' },
  { name: 'Games', path: '/seigames' },
  {
    name: 'NFTs',
    dropdown: true,
    dropdownLinks: [
      { name: 'Exchanges/Launchpads', path: '/nfts' },
      { name: 'Analytics', path: '/analytics' }, // Ensure Analytics is visible
    ],
  },
  {
    name: 'Community',
    dropdown: true,
    dropdownLinks: [
      { name: 'NFT Communities', path: '/communities' },
      { name: 'Meme Coins', path: '/meme' },
      { name: 'Twitter Spaces', path: '/spaces' },
    ],
  },
  {
    name: 'Infrastructure',
    dropdown: true,
    dropdownLinks: [
      { name: 'Bridges / Swaps', path: '/bridges' },
      { name: 'Explorers', path: '/explorers' },
      { name: 'Other Tools', path: '/other-tools' },
    ],
  },
  {
    name: 'Other',
    dropdown: true,
    dropdownLinks: [
      { name: 'Media Kits', path: '/mediakits' },
      { name: 'Dev Stuff', path: '/devstuff' },
    ],
  },
];

export default navLinks;
