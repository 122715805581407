// src/components/Navbar.js

import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';  // Import axios
import { Link } from 'react-router-dom';
import logo from '../images/logo.png';
import seiLogo from '../icons/SEI.png';  // Import the SEI logo
import navLinks from '../data/NavData';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [seiPrice, setSeiPrice] = useState(null); // State to store SEI price
  const menuRef = useRef();
  const buttonRef = useRef();
  const dropdownRef = useRef();

  const getCurrentSeiPrice = async () => {
    try {
      const response = await axios.get('https://api.coingecko.com/api/v3/simple/price?ids=sei-network&vs_currencies=usd');
      setSeiPrice(parseFloat(response.data['sei-network'].usd));  // Update SEI price
    } catch (error) {
      console.error('Error fetching SEI price:', error);
    }
  };

  useEffect(() => {
    getCurrentSeiPrice();  // Fetch SEI price when the component mounts
    window.addEventListener('resize', handleResize);
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
    setActiveDropdown(null); // Close the dropdown when the menu is closed
  };

  const handleClickOutside = (event) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target) &&
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target)
    ) {
      closeMenu();
    }
  };

  const handleResize = () => {
    setIsMobileView(window.innerWidth < 768);
    if (window.innerWidth >= 768) {
      setIsOpen(false);
      setActiveDropdown(null);
    }
  };

  const toggleDropdown = (index) => {
    setActiveDropdown(activeDropdown === index ? null : index); // Toggle the active dropdown
  };

  return (
    <nav className="bg-gray-800 shadow-lg">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16 items-center">
          <div className="flex items-center">
            <img src={logo} alt="Logo" className="w-10 h-10 mr-4" />
            <div className="flex flex-col text-white">
              <h1 className="text-xl font-bold uppercase">SEIGuide.xyz</h1>
              <a href="https://www.craftycanines.xyz/" className="text-xs font-normal" target="_blank" rel="noopener noreferrer">
                Powered By: Crafty Canines
              </a>
            </div>
          </div>
          {/* SEI Price Section */}
          <div className="flex items-center text-white ml-4">
            <img src={seiLogo} alt="SEI Logo" className="w-6 h-6 mr-2" />
            {seiPrice !== null ? (
              <span className="text-lg font-semibold">${seiPrice.toFixed(2)}</span>
            ) : (
              <span className="text-sm">Loading...</span>
            )}
          </div>
          {!isMobileView ? (
            <div className="hidden md:flex items-center space-x-1">
              {navLinks.map((link, index) =>
                link.dropdown ? (
                  <div className="relative" key={index}>
                    <button
                      onClick={() => toggleDropdown(index)}
                      className="text-white text-sm hover:bg-blue-600 px-3 py-2 rounded flex items-center"
                    >
                      {link.name}
                      {activeDropdown === index ? (
                        <FaChevronUp className="ml-1" />
                      ) : (
                        <FaChevronDown className="ml-1" />
                      )}
                    </button>
                    {activeDropdown === index && (
                      <div
                        ref={dropdownRef}
                        className="absolute bg-gray-700 text-white mt-1 rounded-md shadow-lg z-10 w-48"
                      >
                        {link.dropdownLinks.map((sublink, subIndex) => (
                          <Link
                            key={subIndex}
                            to={sublink.path}
                            className="block text-sm hover:bg-blue-600 px-4 py-2 rounded whitespace-nowrap"
                            onClick={closeMenu}
                          >
                            {sublink.name}
                          </Link>
                        ))}
                      </div>
                    )}
                  </div>
                ) : (
                  <Link
                    key={index}
                    to={link.path}
                    className="text-white text-sm hover:bg-blue-600 px-3 py-2 rounded"
                    onClick={closeMenu}
                  >
                    {link.name}
                  </Link>
                )
              )}
            </div>
          ) : (
            <div className="flex md:hidden items-center">
              <button
                ref={buttonRef}
                onClick={toggleMenu}
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 focus:text-white"
              >
                {isOpen ? (
                  <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                ) : (
                  <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
                  </svg>
                )}
              </button>
            </div>
          )}
        </div>
      </div>

      {isMobileView && isOpen && (
        <div
          ref={menuRef}
          className="md:hidden navbar-dropdown bg-gray-700 text-white shadow-lg border-l border-gray-600 rounded-lg"
          style={{ right: '10px', width: 'auto', minWidth: '100px', maxWidth: '200px', padding: '10px', position: 'absolute' }}
        >
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            {navLinks.map((link, index) =>
              link.dropdown ? (
                <div key={index}>
                  <button
                    onClick={() => toggleDropdown(index)}
                    className="block text-white hover:bg-blue-600 px-3 py-2 rounded text-right flex items-center justify-end"
                  >
                    {link.name}
                    {activeDropdown === index ? (
                      <FaChevronUp className="ml-1" />
                    ) : (
                      <FaChevronDown className="ml-1" />
                    )}
                  </button>
                  {activeDropdown === index && (
                    <div className="pl-4">
                      {link.dropdownLinks.map((sublink, subIndex) => (
                        <Link
                          key={subIndex}
                          to={sublink.path}
                          className="block text-sm hover:bg-blue-600 px-3 py-2 rounded"
                          onClick={closeMenu}
                        >
                          {sublink.name}
                        </Link>
                      ))}
                    </div>
                  )}
                </div>
              ) : (
                <Link
                  key={index}
                  to={link.path}
                  className="block text-white hover:bg-blue-600 px-3 py-2 rounded text-right"
                  onClick={closeMenu}
                >
                  {link.name}
                </Link>
              )
            )}
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
