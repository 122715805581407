import compassLogo from '../images/Compass.png';
import metamaskLogo from '../images/MetaMask.png';

export const walletsData = [
    {
        logo: compassLogo,
        title: "Compass Wallet",
        description: "Compass Wallet offers a comprehensive Sei Network experience with in-wallet staking, a detailed NFT gallery, and extensive activity history.",
        features: [
            'Supports most Sei Network tokens, including custom tokens.',
            'Access any Sei Network dApp with the Chrome extension.',
        ],
        links: [
            { label: 'Website', href: 'https://compasswallet.io/' },
            { label: 'Developer Docs', href: 'https://docs.leapwallet.io/cosmos/sei-dapps/connect-to-compass' },
            { label: 'Twitter', href: 'https://x.com/compass_wallet' },
            { label: 'Download', href: 'https://compasswallet.io/download' }
        ]
    },
    {
        logo: metamaskLogo,
        title: "MetaMask Wallet",
        description: `MetaMask allows you to manage and track all your web3 activities in one place, providing convenience and extensive functionality.`,
        features: [
            'Supports Sei EVM and any Sei-based token.',
            'Easily install MetaMask from the official website and access various dApps.',
        ],
        links: [
            { label: 'Website', href: 'https://metamask.io/' },
            { label: 'Developer Docs', href: 'https://docs.metamask.io/' },
            { label: 'Twitter', href: 'https://x.com/MetaMask' },
            { label: 'Download', href: 'https://metamask.io/download/' }
        ],
        networkInfo: {
            chainId: '1329',
            currency: 'SEI',
            rpcUrls: ['https://evm-rpc.sei-apis.com'],
            blockExplorerUrl: 'https://seitrace.com'
        }
    }
];
