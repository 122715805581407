import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { FaTwitter, FaGlobe, FaFileAlt, FaDownload, FaCopy, FaCheck } from 'react-icons/fa';
import { walletsData } from '../data/walletsData';
import SEILogo from '../icons/SEI.png';

const cardHoverVariants = {
    hover: {
        scale: 1.05,
        transition: {
            duration: 0.3,
            ease: 'easeInOut',
        },
    },
};

const WalletCard = ({ logo, title, description, features, links, networkInfo }) => {
    const [copiedIndex, setCopiedIndex] = useState(null);

    const handleCopy = (url, index) => {
        navigator.clipboard.writeText(url);
        setCopiedIndex(index);
        setTimeout(() => {
            setCopiedIndex(null);
        }, 2000);
    };

    return (
        <motion.div
            initial={{ opacity: 0, y: 50, scale: 0.9 }}
            animate={{ opacity: 1, y: 0, scale: 1 }}
            exit={{ opacity: 0, y: -50, scale: 0.9 }}
            whileHover="hover"
            variants={cardHoverVariants}
            className="bg-gray-900 p-4 md:p-6 rounded-lg shadow-lg flex flex-col items-center space-y-4 mb-8 w-full max-w-sm md:max-w-2xl mx-auto"
        >
            <img src={logo} alt={`${title} Logo`} className="w-16 h-16 md:w-24 md:h-24 rounded-full" />
            <div className="text-left w-full">
                <h3 className="text-lg md:text-2xl font-bold mb-2">{title}</h3>
                <p className="text-sm md:text-gray-400 mb-2">{description}</p>
                <ul className="list-disc list-inside text-sm md:text-gray-400 mb-2 space-y-1">
                    {features.map((feature, index) => (
                        <li key={index} className="whitespace-normal">{feature}</li>
                    ))}
                </ul>

                {networkInfo && (
                    <div className="bg-gray-800 p-3 mt-4 rounded-lg">
                        <h4 className="font-bold text-md text-white">Metamask Network Info</h4>
                        <p className="text-sm text-gray-400">
                            <strong>Currency:</strong> {networkInfo.currency}<br />
                            <strong>Chain ID:</strong> {networkInfo.chainId}<br />
                            <strong>RPC URL: </strong>
                            {networkInfo.rpcUrls.map((url, index) => (
                                <span key={index} className="inline-flex items-center">
                                    <span className="font-mono text-gray-400">{url}</span>
                                    {copiedIndex === index ? (
                                        <FaCheck className="text-green-500 ml-1" size={14} title="Copied!" />
                                    ) : (
                                        <FaCopy
                                            className="cursor-pointer text-gray-400 hover:text-gray-600 ml-1"
                                            onClick={() => handleCopy(url, index)}
                                            size={14}
                                            title="Copy RPC URL"
                                        />
                                    )}
                                    {index < networkInfo.rpcUrls.length - 1 && <span>,&nbsp;</span>}
                                </span>
                            ))}
                            <br />
                            <strong>Block Explorer: </strong>
                            <span className="inline-flex items-center">
                                <a href={networkInfo.blockExplorerUrl} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:text-blue-700">
                                    {networkInfo.blockExplorerUrl}
                                </a>
                                {copiedIndex === 'explorer' ? (
                                    <FaCheck className="text-green-500 ml-1" size={14} title="Copied!" />
                                ) : (
                                    <FaCopy
                                        className="cursor-pointer text-gray-400 hover:text-gray-600 ml-1"
                                        onClick={() => handleCopy(networkInfo.blockExplorerUrl, 'explorer')}
                                        size={14}
                                        title="Copy Block Explorer URL"
                                    />
                                )}
                            </span>
                        </p>
                    </div>
                )}

                <div className="space-x-4 mt-4 flex items-center">
                    {links.map((link, index) => {
                        let IconComponent;
                        switch (link.label) {
                            case 'Website':
                                IconComponent = <FaGlobe className="text-blue-500 hover:text-blue-700" size={20} />;
                                break;
                            case 'Developer Docs':
                                IconComponent = <FaFileAlt className="text-blue-500 hover:text-blue-700" size={20} />;
                                break;
                            case 'Twitter':
                                IconComponent = <FaTwitter className="text-blue-500 hover:text-blue-700" size={20} />;
                                break;
                            case 'Download':
                                IconComponent = <FaDownload className="text-blue-500 hover:text-blue-700" size={20} />;
                                break;
                            default:
                                IconComponent = null;
                        }
                        return IconComponent ? (
                            <a key={index} href={link.href} target="_blank" rel="noopener noreferrer" aria-label={link.label}>
                                {IconComponent}
                            </a>
                        ) : null;
                    })}
                </div>
            </div>
        </motion.div>
    );
};

const Wallets = () => {
    return (
        <div className="flex flex-col items-center justify-center text-white p-4 pb-16">
            <div className="w-full text-center mb-2">
                <h1 className="text-2xl md:text-4xl font-bold text-center text-white mb-4 flex items-center justify-center">
                    <img src={SEILogo} alt="SEI Logo" className="w-8 h-8 mr-2" />
                    Wallets
                </h1>
            </div>

            <div className="bg-gray-900 p-6 rounded-lg shadow-lg flex flex-col items-center space-y-4 mt-4 mb-4 w-full max-w-3xl mx-auto">
                <p className="text-sm md:text-gray-400 text-center">
                    When you create a wallet, it generates both a Sei native address (sei) and an EVM address (0x) from a single private key.
                </p>

                <div className="flex justify-center space-x-6">
                    <div className="text-sm md:text-gray-400">
                        • <span className="font-mono text-xs md:text-base">SEI address example: sei1wz...wen8hp</span>
                        <br />
                        • <span className="font-mono text-xs md:text-base">EVM address example: 0x23c6...fa70cd</span>
                    </div>
                </div>

                <p className="text-sm md:text-gray-400 text-center">
                    To sync balances across both sides of the chain, you need to link your addresses by signing a transaction. The easiest way to do this is by sending SEI back to yourself within your wallet of choice. You can also link addresses using Sei's official page:
                    <a href="https://app.sei.io/" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:text-blue-700"> https://app.sei.io/</a>
                </p>

                <p className="text-sm md:text-gray-400 text-center">
                    You can receive SEI on both sides independently without linking, but the balances will appear mismatched until you sign a transaction to link them.
                </p>
            </div>

            <div className="hidden md:grid w-full max-w-5xl grid-cols-1 md:grid-cols-2 gap-4 mt-6">
                {walletsData.map((card, index) => (
                    <WalletCard key={index} {...card} />
                ))}
            </div>
            <div className="md:hidden w-full">
                {walletsData.map((card, index) => (
                    <WalletCard key={index} {...card} />
                ))}
            </div>
        </div>
    );
};

export default Wallets;
