// data/timePeriods.js

const timePeriods = {
  "1day": 86400000,          // 1 day in milliseconds
  "7day": 604800000,         // 7 days in milliseconds
  "14day": 1209600000,       // 14 days in milliseconds
  "30day": 2592000000,       // 30 days in milliseconds
  "all_time": 77760000000    // 900 days in milliseconds (all time)
};

export default timePeriods;
