// src/data/axiosCodeData.js

const axiosCodeData = {
  "Axios Specific Code": [
    {
      title: "Axios Example for Querying Token Info",
      description: "Example code using Axios to query data from a Sei blockchain smart contract.",
      code: `
const axios = require('axios');

const lcdURL = 'https://sei-api.polkachu.com';
const contractAddress = "sei1c7tj004asc9n6zhkk950u0nvxewwswanvg95aqazqs29q3l6nq9sqtrtwl";
const queryMsg = {
  "all_nft_info": {
    "token_id": "420"
  }
};

const queryContract = async () => {
  const queryB64Encoded = Buffer.from(JSON.stringify(queryMsg)).toString('base64');
  const res = await axios.get(\`\${lcdURL}/cosmwasm/wasm/v1/contract/\${contractAddress}/smart/\${queryB64Encoded}\`);
  console.log(res.data);
};

queryContract();
`
    },
    {
      title: "Axios Example for Fetching SEI Price from CoinGecko",
      description: "Example code using Axios to query the current SEI token price in USD from CoinGecko.",
      code: `
const axios = require('axios');

const getCurrentSeiPrice = async () => {
  try {
    const response = await axios.get('https://api.coingecko.com/api/v3/simple/price?ids=sei-network&vs_currencies=usd');
    const seiPrice = parseFloat(response.data['sei-network'].usd);
    console.log('SEI Price in USD:', seiPrice);
    return seiPrice;
  } catch (error) {
    console.error('Error fetching SEI price:', error);
    return null;
  }
};

getCurrentSeiPrice();
`
    }
  ]
};

export default axiosCodeData;
